 <template>
  <div class>
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Change Password</h4>
                  <p>Please enter your new password.</p>
                  <!-- <vs-alert
                    :active.sync="showDismissibleAlert"
                    closable
                    icon-pack="feather"
                    close-icon="icon-x"
                  >{{message}}</vs-alert> -->
                </div>
                <form>
                  <vs-input
                    type="password"
                    name="currentPassword"
                    label-placeholder="Current Password"
                    v-model="user.currentPassword"
                    class="w-full mb-6"
                    data-vv-validate-on="blur"
                    data-vv-as="current password"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm">{{ errors.first('currentPassword') }}</span>
                  <vs-input
                    type="password"
                    name="newPassword"
                    label-placeholder="New Password"
                    v-model="user.newPassword"
                    class="w-full mb-6"
                    data-vv-validate-on="blur"
                    data-vv-as="password"
                    v-validate="'required|passwordFormat'"
                    ref="newPassword"
                  />
                  <span class="text-danger text-sm">{{ errors.first('newPassword') }}</span>
                  <vs-input
                    type="password"
                    name="confirmNewPassword"
                    label-placeholder="Confirm New Password"
                    v-model="user.confirmNewPassword"
                    class="w-full mb-8"
                    data-vv-validate-on="blur"
                    data-vv-as="password"
                    v-validate="'required|confirmed:newPassword'"
                  />
                  <span class="text-danger text-sm">{{ errors.first('confirmNewPassword') }}</span>
                </form>

                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="!validateForm"
                    @click="changePassword"
                  >Save Changes</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

    <script>
import { mapActions } from "vuex";
import { authMixin } from "../../../mixins/authMixin"
export default {
  mixins: [authMixin],
  data() {
    return {
      user: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },
      showDismissibleAlert: false,
      message: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.user.currentPassword != "" &&
        this.user.newPassword != "" &&
        this.user.newPassword == this.user.confirmNewPassword
      );
    }
  },
  methods: {
    ...mapActions("users", ["changePassword"]),
    changePassword() {
      const payload = {
        userDetails: this.user
      };
      console.log(payload);
      this.$validator.validateAll().then(async(result) => {
        if (result) {
          this.$vs.loading();
          const response = await this.changeAdminPassword(this.user.currentPassword, this.user.newPassword)
            this.$vs.loading.close();

            if(response.message && response.message.code == 200){
              
                this.$vs.notify({
                  title: 'Success',
                  text: response.message.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
                this.showDismissibleAlert = true
                this.message = response.message.message
                const clear = async () => {
                                    this.user.currentPassword = ''
                                    this.user.newPassword = ''
                                    this.user.confirmNewPassword = ''
                                }
                                clear().then(() => {
                                    this.$validator.reset()
                                })
            }
            else{
              this.errors.add({
                field: 'currentPassword',
                msg: 'Old password does not match.'
              })
                this.showDismissibleAlert = true
                this.message = "Old password does not match."
                this.$vs.notify({
                  title: 'Error',
                  text: "Old password does not match.",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
            }
              // this.$router.push("/success");
        }
      });
    }
  },
  created() {
    this.$validator.extend("passwordFormat", {
      getMessage: () =>
        "Password must contain at least one uppercase, lowercase, digit and 8 characters long",
      validate: value =>
        value.match(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/
        ) !== null
    });
  }
};
</script>
